export const CUSTOMER_SEGMENT = {
    description: '',
    name: '',
    notes: '',
    recurring_billing: 0,
    price_include_vat: true,
    interest_billing: true,
    active: true,
    invoice_discount: '',
    invoice_administration_fee: 0,
    invoice_freight: 0,
    invoice_charge: '',
    shipping_charge: '',
    type_of_vat: 'SEVAT',
    invoice: 'email',
    quote: 'email',
    order: 'email',
    invoice_text: ''
}

export const ARTICLE_SEGMENT = {
    name: '',
    description: '',
    notes: '',
    average_monthly_earnings: 0,
    manufacturer: '',
    supplier: '',
    inventory_location: '',
    unit: '',
    discontinued: true,
    stock_item: true,
    external_webshop: true,
    product_type: 'STOCK',
    sales_account_se: '',
    sales_account_eu_vat: '',
    sales_account_export: '',
    purchase_account: '',
}